<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="'组件管理新增'"
      @head-save-back="headSave('back')"
      @head-save="headSave('save')"
      @head-cancel="headCancel"
    >
    </head-layout>

    <form-layout
      :column="column"
      :dataForm.sync="dataObj"
      ref="formLayout"
    ></form-layout>
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {
  SavecomponentManage,
  componentManageDetail,
} from "@/api/dataAcquisition/index";
export default {
  components: { FormLayout, HeadLayout },
  computed: {
  },
  data() {
    return {
      parentId: 0,
      headBtnOptions: [
        {
          label: this.$t("cip.cmn.btn.saveBtn"),
          emit: "head-save",
          type: "button",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.saveBackBtn"),
          emit: "head-save-back",
          type: "button",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-cancel",
          type: "button",
          icon: "",
        },
      ],
      dataObj: {},
      column: [
        {
          label: "组件名称",
          prop: "name",
          search: true,
          rules: [{ required: true, trigger: ["blur", "change"],message:this.$t("cip.cmn.rule.inputWarning") + "组件名称", }],
        },
        {
          label: "组件code",
          prop: "code",
          rules: [
            {
              required: true,
              message:this.$t("cip.cmn.rule.inputWarning") + "组件code",
              trigger: "blur",
            },
          ],
        },




        {
          label: "类型",
          prop: "type",
          type: "select",
          dataType: "number",
          props: {
            label: "label",
            value: "value",
          },
          // dicData: [
          //   {
          //     label: "输入",
          //     value: 1,
          //   },
          //   {
          //     label: "输出",
          //     value: 2,
          //   },
          //    {
          //     label: "业务组件",
          //     value: 3,
          //   },
          // ],
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=Input_Output",

        //   value: 1,
          rules: [
            {
              required: true,
              message:
                this.$t("cip.cmn.rule.inputWarning") +"类型",
              trigger: "blur",
            },
          ],
        },

        {
          label: "是否落库",
          prop: "isSave",
          type: "select",
          // dicData: [
          //   {
          //     label: this.$t("cip.plat.sys.menu.field.no"),
          //     value: 0,
          //   },
          //   {
          //     label: this.$t("cip.plat.sys.menu.field.yes"),
          //     value: 1,
          //   },
          // ],
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dataType: 'number',
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no",
      //   value: 1,
          rules: [
            {
              required: true,
              message:
                this.$t("cip.cmn.rule.inputWarning") + "是否落库",
              trigger: "blur",
            },
          ],
        },




        {
          label: "组件内容",
          prop: "content",
          type: "textarea",
          maxlength: 500,
          showWordLimit: true,
          minRows: 3,
          rules: [
            {
              required: true,
              message:
                this.$t("cip.cmn.rule.inputWarning") + "组件内容",
              trigger: "blur",
            },
          ],
        },
      ],
    };
  },
  created() {
    console.log(this.$route.query);
    if (this.$route.query.type == "edit") {
      componentManageDetail({id:this.$route.query.id}).then((res) => {
        console.log(res.data.data);
        this.dataObj = res.data.data;
      });
    }
  },
  mounted() {},

  methods: {
    headSave(type) {
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          SavecomponentManage(this.dataObj)
            .then(
              (res) => {
                if (type == "back") {
                  this.close();
                }
                this.dataObj = res.data.data;
                this.$message({
                  type: "success",
                  message: this.$t("cip.cmn.msg.success.operateSuccess"),
                });

              },
              (error) => {
                window.console.log(error);
              }
            )
            .finally(() => {
              this.$refs.formLayout.$refs.form.allDisabled = false;
              this.$loading().close();
            });
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false;
        }
      });
    },

    close() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  },
};
</script>

<style>
</style>
